import BigNumber from 'bignumber.js';

export function calculateProfit(asset, order) {
  const tradeSign = order.isLong ? 1 : -1;
  const betSize = new BigNumber(order.stake.baseAmount);
  const entryPrice = new BigNumber(order.entryPrice);
  const currentPrice = new BigNumber(order.exitPrice ?? asset.price);
  const leverage = order.leverage;
  const isProfitable = (order.isLong && currentPrice.isGreaterThan(entryPrice)) || (!order.isLong && currentPrice.isLessThan(entryPrice));

  const closePrice = isProfitable
    ? calculateAdjustedClosePrice(asset, currentPrice, entryPrice, betSize, leverage)
    : currentPrice;

  const profit = calculateProfitAmount(betSize, leverage, closePrice, entryPrice, tradeSign);

  return BigNumber.max(profit, betSize.negated());
}

function calculateAdjustedClosePrice(asset, currentPrice, entryPrice, betSize, leverage) {
  const { baseRate, rateMultiplier, rateExponent, positionMultiplier, } = asset;

  const r = currentPrice.dividedBy(entryPrice).minus(1);
  const m = r.multipliedBy(rateMultiplier);
  const e = m.pow(rateExponent);
  const b = betSize
  .multipliedBy(leverage)
  .dividedBy(betSize.pow(6).multipliedBy(r).multipliedBy(positionMultiplier))
  .decimalPlaces(8, BigNumber.ROUND_HALF_EVEN);

  const adjustmentFactor = new BigNumber(1)
  .minus(baseRate)
  .dividedBy(new BigNumber(1).plus(new BigNumber(1).dividedBy(e).plus(b)))
  .decimalPlaces(8, BigNumber.ROUND_HALF_EVEN);

  return adjustmentFactor
  .multipliedBy(currentPrice.minus(entryPrice))
  .plus(entryPrice)
  .decimalPlaces(8, BigNumber.ROUND_HALF_EVEN);
}

function calculateProfitAmount(betSize, leverage, closePrice, entryPrice, tradeSign) {
  const profit = betSize
  .multipliedBy(leverage)
  .multipliedBy(closePrice.dividedBy(entryPrice).minus(1))
  .multipliedBy(tradeSign);

  const roundingMode = profit.isNegative() ? BigNumber.ROUND_UP : BigNumber.ROUND_DOWN;

  return profit.decimalPlaces(2, roundingMode);
}
