import BigNumber from 'bignumber.js';
import { calculateProfit } from './calculateProfit';
import { calculateFee } from './calculateFee';

export function calculatePnl(asset, order) {
  if (!asset) {
    return {
      pnl: 0,
      roi: 0,
      fee: 0,
    };
  }

  const { baseAmount, } = order.stake;
  const pnl = calculateProfit(asset, order);

  return {
    pnl: pnl.toNumber(),
    roi: pnl.dividedBy(baseAmount).multipliedBy(100).decimalPlaces(2, BigNumber.ROUND_HALF_EVEN).toNumber(),
    fee: calculateFee(asset, order).toNumber(),
  };
}
