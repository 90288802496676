import BigNumber from 'bignumber.js';
import { calculateProfit } from './calculateProfit';

export function calculateFee(asset, order) {
  const tradeSign = order.isLong ? 1 : -1;
  const entryPrice = new BigNumber(order.entryPrice);
  const betSize = new BigNumber(order.stake.baseAmount);
  const leverage = order.leverage;
  const price = new BigNumber(order.exitPrice ?? asset.price);
  const pnl = calculateProfit(asset, order);

  if (pnl.isNegative()) {
    return new BigNumber(0);
  }

  return betSize
  .multipliedBy(leverage)
  .multipliedBy(price.dividedBy(entryPrice).minus(1))
  .decimalPlaces(2, BigNumber.ROUND_HALF_EVEN)
  .multipliedBy(tradeSign)
  .minus(pnl);
}
